import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const Pagination = ({ page, pages, changePage }) => {
  let middlePagination;

  if (pages <= 5) {
    middlePagination = [...Array(pages)].map((_, idx) => (
      <li className="page-item">
        <button
          className="page-link"
          key={idx + 1}
          onClick={() => changePage(idx + 1)}
          disabled={page === idx + 1}
        >
          {idx + 1}
        </button>
      </li>
    ));
  } else {
    const startValue = Math.floor((page - 1) / 5) * 5;

    middlePagination = (
      <>
        {[...Array(5)].map((_, idx) => (
          <li className="page-item ">
            <button
              className="page-link"
              key={startValue + idx + 1}
              disabled={page === startValue + idx + 1}
              onClick={() => changePage(startValue + idx + 1)}
            >
              {startValue + idx + 1}
            </button>
          </li>
        ))}

        <li className="page-item disabled">
          <button className="page-link">...</button>
        </li>
        <li className="page-item">
          {" "}
          <button className="page-link" onClick={() => changePage(pages)}>
            {pages}
          </button>
        </li>
      </>
    );

    if (page > 5) {
      if (pages - page >= 5) {
        middlePagination = (
          <>
            <li className="page-item">
              <button className="page-link" onClick={() => changePage(1)}>
                1
              </button>
            </li>
            <li className="page-item">
              <button className="page-link">...</button>
            </li>
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => changePage(startValue)}
              >
                {startValue}
              </button>
            </li>
            {[...Array(5)].map((_, idx) => (
              <li className="page-item">
                <button
                  className="page-link"
                  key={startValue + idx + 1}
                  disabled={page === startValue + idx + 1}
                  onClick={() => changePage(startValue + idx + 1)}
                >
                  {startValue + idx + 1}
                </button>
              </li>
            ))}

            <li className="page-item">
              <button className="page-link">...</button>
            </li>
            <li className="page-item">
              <button className="page-link" onClick={() => changePage(pages)}>
                {pages}
              </button>
            </li>
          </>
        );
      } else {
        let amountLeft = pages - page + 5;
        middlePagination = (
          <>
            <li className="page-item">
              <button className="page-link" onClick={() => changePage(1)}>
                1
              </button>
            </li>
            <li className="page-item">
              <button className="page-link">...</button>
            </li>
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => changePage(startValue)}
              >
                {startValue}
              </button>
            </li>
            {[...Array(amountLeft)].map((_, idx) => (
              <li className="page-item">
                <button
                  className="page-link"
                  key={startValue + idx + 1}
                  disabled={page === startValue + idx + 1}
                  style={
                    pages < startValue + idx + 1 ? { display: "none" } : null
                  }
                  onClick={() => changePage(startValue + idx + 1)}
                >
                  {startValue + idx + 1}
                </button>
              </li>
            ))}
          </>
        );
      }
    }
  }

  return (
    pages > 1 && (
      <ul class="pagination justify-content-center">
        <li className="page-item">
          <button
            className="page-link"
            onClick={() => changePage((page) => page - 1)}
            disabled={page === 1}
          >
            <AiOutlineLeft />
          </button>
        </li>
        {middlePagination}
        <li className="page-item">
          <button
            className="page-link"
            onClick={() => changePage((page) => page + 1)}
            disabled={page === pages}
          >
            <AiOutlineRight />
          </button>
        </li>
      </ul>
    )
  );
};

export default Pagination;
