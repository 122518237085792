/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router-dom";
import { Stat } from "../components/admin/homeComponents";
import { BiDollar, BiMoney } from "react-icons/bi";
import { MdAccountBalanceWallet } from "react-icons/md";
import ReactDatatable from "@ashvin27/react-datatable";
import Loading from "react-fullscreen-loading";
import { useTranslation } from "react-i18next";

const Login = () => {
  const history = useHistory();
  const [email, setemail] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [screenLoading, setscreenLoading] = useState(false)
  const [refresh, setrefresh] = useState(false);
  const [user, setuser] = useState("");
  const [transactions, settransactions] = useState();
  const [amount, setamount] = useState();
  const [method, setmethod] = useState("paypal");
  const [detail, setdetail] = useState("");
  const [minWithdraw, setminWithdraw] = useState();
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisLoading(true);
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const data = {
      email: email,
      amount: parseInt(amount),
      detail: detail,
      method: method,
    };
    try {
      const response = await axios.post(`/api/withdraw`, data, config);
      toast.success(response.data.msg);
      setisLoading(false);
      setrefresh(true);
    } catch (err) {
      toast.error(err.response.data.msg);
      setisLoading(false);
    }
  };

  const columns = [
    {
      key: "method",
      text: t("Payment method"),
      sortable: true,
    },
    {
      key: "amount",
      text: t("Amount($)"),
      sortable: true,
    },
    {
      key: "status",
      text: t("Status"),
      sortable: true,
    },
  ];
  const config = {
    page_size: 4,
    length_menu: [5, 10, 25],
    show_filter: false,
    show_pagination: true,
    pagination: "advance",
  };

  useEffect(async () => {
    const token = Cookies.get("token");
    if (!token) {
      history.push("/");
    } else {
      const decode = jwtDecode(token);
      setemail(decode.user.email);
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      setscreenLoading(true)
      try {
        const response = await axios.post(`/api/setting`, config);
        if(response.data.setting.earning !== "true"){
          history.push("/");
        }
        setmethod(response.data.setting.withdrawMethod);
        setminWithdraw(response.data.setting.minWithdraw);
      } catch (err) {}
      const data = {
        email: decode.user.email,
      };
      try {
        const response = await axios.post(`/api/users`, data, config);
        setuser(response.data.user[0]);
      } catch (err) {}
      try {
        const response = await axios.post(`/api/transections`, data, config);
        settransactions(response.data.userTransections);
      } catch (err) {}
    }
    setrefresh(false);
    setscreenLoading(false)
  }, [refresh]);

  return (
    <div className="dashboard">
      <svg
        className="header-bg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#0099ff"
          fillOpacity="1"
          d="M0,32L48,64C96,96,192,160,288,170.7C384,181,480,139,576,144C672,149,768,203,864,224C960,245,1056,235,1152,234.7C1248,235,1344,245,1392,250.7L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <Header refresh={refresh} />
      {
        screenLoading ? (<Loading loading background="#0000004d" loaderColor="#0099ff" />) : ""
      }
      <Toaster position="bottom-right" reverseOrder={false} />
      <div className="container">
        <div className="center-content">
          <div className="row stats">
            <div className="col-lg-4">
              <Stat
                Icon={BiDollar}
                title={t("Total Earning")}
                data={`${user.earning}$`}
              />
            </div>
            <div className="col-lg-4">
              <Stat
                Icon={BiMoney}
                title={t("Total Withdraw")}
                data={`${user.withdraw}$`}
              />
            </div>
            <div className="col-lg-4">
              <Stat
                Icon={MdAccountBalanceWallet}
                title={t("Available balance")}
                data={`${user.balance}$`}
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6">
              <span>{t("Transactions")}</span>
              <div className="ttable p-3">
                <ReactDatatable
                  config={config}
                  records={transactions}
                  columns={columns}
                />
              </div>
            </div>
            <div className="col-lg-6 wsec">
              <span>{t("Submit a payment request")}</span>
              <form className="m-4" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>{t("Amount:")}</label>
                  <input
                    type="number"
                    className="form-control input"
                    placeholder={t("Enter amount")}
                    value={amount}
                    onChange={(e) => setamount(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label >{t("Payment method:")}</label>
                  <br />
                  <label className="form-check-label ml-4">
                    <input
                      type="radio"
                      className="form-check-input"
                      checked
                      value={method}
                      onChange={(e) => setmethod(e.target.value)}
                      required
                    />
                    {method}{` - ${t("Minimum")} ${minWithdraw}$`}
                  </label>
                </div>
                <div className="form-group">
                  <label>{t("Account detail:")}</label>
                  <input
                    type="username"
                    className="form-control input"
                    placeholder={t("Eg: username,email or number")}
                    value={detail}
                    onChange={(e) => setdetail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  {isLoading ? (
                    <button
                      type="submit"
                      disabled
                      className="form-control btn btn-primary mt-4 create-btn mb-3"
                    >
                      <span className="spinner-border"></span>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="form-control btn btn-primary mt-4 create-btn mb-3"
                    >
                      {t("Submit request")}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
