/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import Switch, { Case } from "react-switch-case";
import {
  AiFillYoutube,
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterSquare,
} from "react-icons/ai";
import { GiCheckMark } from "react-icons/gi";
import { Adsense } from "@ctrl/react-adsense";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment-timezone";
import parse from "html-react-parser";
import InnerHTML from 'dangerously-set-html-content';
import publicIP from 'react-native-public-ip';


const LinkPage = () => {
  const [socialLinks, setsocialLinks] = useState([]);
  const [recentposts, setrecentposts] = useState([]);
  const [post, setpost] = useState();
  const [destinationLink, setdestinationLink] = useState("");
  const [destination, setdestination] = useState(false);
  const [client, setclient] = useState();
  const [slot, setslot] = useState();
  const [banner_large, setbanner] = useState();
  const [banner_medium, setbannerMedium] = useState();
  const [banner_small, setbannerSmall] = useState();
  const params = useParams();
  let current_ip;


  const checkLink = (index) => {
    const values = [...socialLinks];
    if (index === values.length - 1) {
      values[index]["check"] = true;
      values[index]["enable"] = false;
    } else {
      values[index]["check"] = true;
      values[index]["enable"] = false;
      values[index + 1]["enable"] = true;
    }
    var timeleft = 3;
    var downloadTimer = setInterval(function () {
      if (timeleft <= 0) {
        clearInterval(downloadTimer);
        setsocialLinks(values);
        if (socialLinks[socialLinks.length - 1]["check"]) {
          setdestination(true);
        }
      }
      timeleft -= 1;
    }, 1000);
  };

  useEffect(async () => {
    const { id } = params;
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    await publicIP()
    .then(ip => {
      current_ip = ip;
    })
    .catch(error => {
      console.log(error);
    })

    const data = {
      shortId: id,
      currentIp: current_ip
    };
    try {
      const response = await axios.post(`/api/single_link`, data, config);
      const values = JSON.parse(response.data.getLink[0].social);
      if (values.length === 0) {
        setdestination(true);
      }
      values[0]["enable"] = true;
      setsocialLinks(values);
      setdestinationLink(response.data.getLink[0].destination);
    } catch (err) {}
    try {
      const response = await axios.post(`/api/setting`, config);
      setclient(response.data.setting.client);
      setslot(response.data.setting.slot);
      setbanner(response.data.setting.banner_large);
      setbannerMedium(response.data.setting.banner_medium);
      setbannerSmall(response.data.setting.banner_small);
      ReactGA.initialize(response.data.setting.trackingID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    } catch (err) {}
    try {
      const response = await axios.post(`/api/posts`, data, config);
      setpost(response.data.randomepost);
      setrecentposts(response.data.recentposts);
    } catch (err) {}
    

  }, []);
  return (
    <div className="linkPage">
      {client ? (
        <Helmet>
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
          ></script>
        </Helmet>
      ) : (
        ""
      )}
      <svg
        className="header-bg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#0099ff"
          fillOpacity="1"
          d="M0,32L48,64C96,96,192,160,288,170.7C384,181,480,139,576,144C672,149,768,203,864,224C960,245,1056,235,1152,234.7C1248,235,1344,245,1392,250.7L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <Header />
      <div className="center-content">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div className="content">
              <div className="footer-aad">
                {client ? <Adsense client={client} slot={slot} /> : ""}
              </div>
              <h4 className="h4">COMPLETE THE FOLLOWING STEPS TO CONTINUE</h4>
              {socialLinks.map((link, index) => (
                <Switch key={index} condition={link.method}>
                  <Case value="youtube">
                    <div className="group">
                      <span className="mt-2">
                        <AiFillYoutube style={{ color: "red" }} size="27px" />
                        <span className="text-primary mt-1 ml-2">Youtube</span>
                      </span>
                      <div className="sbtn">
                        <a
                          onClick={() => checkLink(index)}
                          href={link.url}
                          target="_blank"
                          className={`btn  btn-primary sbtn mr-3 ${
                            link.enable === true ? "" : "disabled"
                          } `}
                        >
                          Subscribe
                        </a>
                        {link.check ? (
                          <span className="checkIcon">
                            <GiCheckMark size="20px" />
                          </span>
                        ) : (
                          <span
                            className="checkIcon"
                            style={{ background: "white" }}
                          >
                            <GiCheckMark size="20px" />
                          </span>
                        )}
                      </div>
                    </div>
                  </Case>
                  <Case value="facebook">
                    <div className="group">
                      <span className="mt-2">
                        <AiFillFacebook
                          style={{ color: "rgb(10, 101, 175)" }}
                          size="27px"
                        />
                        <span className="text-primary mt-1 ml-2">Facebook</span>
                      </span>
                      <div className="sbtn">
                        <a
                          onClick={() => checkLink(index)}
                          href={link.url}
                          target="_blank"
                          className={`btn  btn-primary sbtn mr-3 ${
                            link.enable === true ? "" : "disabled"
                          } `}
                        >
                          Like
                        </a>
                        {link.check ? (
                          <span className="checkIcon">
                            <GiCheckMark size="20px" />
                          </span>
                        ) : (
                          <span
                            className="checkIcon"
                            style={{ background: "white" }}
                          >
                            <GiCheckMark size="20px" />
                          </span>
                        )}
                      </div>
                    </div>
                  </Case>
                  <Case value="instagram">
                    <div className="group">
                      <span className="mt-2">
                        <AiFillInstagram
                          style={{ color: "pink" }}
                          size="27px"
                        />
                        <span className="text-primary mt-1 ml-2">
                          Instagram
                        </span>
                      </span>
                      <div className="sbtn">
                        <a
                          onClick={() => checkLink(index)}
                          href={link.url}
                          target="_blank"
                          className={`btn  btn-primary sbtn mr-3 ${
                            link.enable === true ? "" : "disabled"
                          } `}
                        >
                          Follow
                        </a>
                        {link.check ? (
                          <span className="checkIcon">
                            <GiCheckMark size="20px" />
                          </span>
                        ) : (
                          <span
                            className="checkIcon"
                            style={{ background: "white" }}
                          >
                            <GiCheckMark size="20px" />
                          </span>
                        )}
                      </div>
                    </div>
                  </Case>
                  <Case value="twitter">
                    <div className="group">
                      <span className="mt-2">
                        <AiFillTwitterSquare
                          style={{ color: "rgb(19, 183, 233)" }}
                          size="27px"
                        />
                        <span className="text-primary mt-1 ml-2">Twitter</span>
                      </span>
                      <div className="sbtn">
                        <a
                          onClick={() => checkLink(index)}
                          href={link.url}
                          target="_blank"
                          className={`btn  btn-primary sbtn mr-3 ${
                            link.enable === true ? "" : "disabled"
                          } `}
                        >
                          Follow
                        </a>
                        {link.check ? (
                          <span className="checkIcon">
                            <GiCheckMark size="20px" />
                          </span>
                        ) : (
                          <span
                            className="checkIcon"
                            style={{ background: "white" }}
                          >
                            <GiCheckMark size="20px" />
                          </span>
                        )}
                      </div>
                    </div>
                  </Case>
                </Switch>
              ))}
              <div className="row">
                <div className="col-lg-12 text-center">
                  <helmet>
                    <p><InnerHTML html={banner_large} /></p> 
                  </helmet>
                </div>
              </div>
              {post ? (
                <div className="blog-content">
                  <h3 className="mb-5 mt-5">{post.title}</h3>
                  <p>
                    {`Published on:  `}
                    <Moment fromNow>{post.createdAt}</Moment>
                  </p>
                  <img
                    src={`/images/${post.thumbnail}`}
                    width="100%"
                    height="auto"
                    alt="thumbnail"
                  />
                  <p className="mt-2">{parse(`${post.content}`)}</p>
                </div>
              ) : (
                ""
              )}

              <div className="aad3">
                {client ? <Adsense client={client} slot={slot} /> : ""}
              </div>
              <div className="row">
                <div className="col-lg-12 text-center">
                  <helmet>
                    <p><InnerHTML html={banner_medium} /></p> 
                  </helmet>
                </div>
              </div>
              <div
                className="footerbtn"
                style={{ width: "100%", height: "100px" }}
              >
                {destination ? (
                  <a href={destinationLink} className="btn btn-primary dbtn">
                    Destination Link
                  </a>
                ) : (
                  <a className="btn disabled btn-primary dbtn">
                    Destination Link
                  </a>
                )}
              </div>
              <div className="row">
                <div className="col-lg-12 text-center">
                  <helmet>
                    <p><InnerHTML html={banner_small} /></p> 
                  </helmet>
                </div>
              </div>
              <div className="aad3">
                {client ? <Adsense client={client} slot={slot} /> : ""}
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 col-12">
            <h4 className="rptitle">Latest Posts</h4>
            <div class="row mt-3">
              {recentposts.map((row) => (
                <div className="col-lg-4 col-sm-6 mb-3">
                  <Link to={`/p/${row.slug}`}>
                    <img
                      src={`/images/${row.thumbnail}`}
                      height="auto"
                      width="100%"
                      alt="thumbnail"
                    />
                    <h5>{row.title}</h5>
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LinkPage;
