/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FaFacebookSquare, FaYoutube, FaTwitterSquare } from "react-icons/fa";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const logo = Cookies.get("logo");
  return (
    <div className="footer" style={{ backgroundImage: "url('/wave.svg')" }}>
        <div className="mcontainer">
          <div className="row">
            <div className="col-lg-4 col-sm-4 f-sec">
              <img src={logo ? ({logo}) : '/logo.png'} height="auto" width="250px" alt="" />
            </div>
            <div className="col-lg-4 col-sm-4 social f-sec">
              <ul>
                <li>
                  <a href="#">
                    <FaFacebookSquare className="fs-icon" size="22px" />{" "}
                    Facebook
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FaYoutube className="fs-icon" size="22px" />
                    Youtube
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FaTwitterSquare className="fs-icon" size="22px" />
                    Twitter
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-sm-4 other-links f-sec">
              <ul>
                <li>
                  <a href="#">{t("Privacy Policy")}</a>
                </li>
                <li>
                  <a href="#">{t("Terms and Conditions")}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Footer;
