/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import axios from "axios";
import Loading from "react-fullscreen-loading";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
import ReactGA from "react-ga";
import "moment-timezone";
import parse from 'html-react-parser';
import {useHistory} from "react-router-dom";

const Blog = () => {
  const history = useHistory();
  const params = useParams();
  const [isLoading, setisLoading] = useState(false);
  const [client, setclient] = useState("");
  const [slot, setslot] = useState("");
  const [banner,setbanner] = useState("");
  const [data, setdata] = useState([]);

  useEffect(async () => {
    const { id } = params;
    setisLoading(true);
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const data = {
      id:id
    };
    try {
      const response = await axios.post(`/api/singlepost`, data, config);
      setdata(response.data.post);
    } catch (err) {
      history.push("/");
    }
    try {
      const response = await axios.post(`/api/setting`, config);
      setclient(response.data.setting.client);
      setslot(response.data.setting.slot);
      setbanner(response.data.setting.banner);
      ReactGA.initialize(response.data.setting.trackingID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    } catch (err) {}
    setisLoading(false);
  }, []);
  return (
    <div className="blog">
      <svg
        className="header-bg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#0099ff"
          fillOpacity="1"
          d="M0,32L48,64C96,96,192,160,288,170.7C384,181,480,139,576,144C672,149,768,203,864,224C960,245,1056,235,1152,234.7C1248,235,1344,245,1392,250.7L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <Header />
      <div className="mcontainer">
        <div className="center-content">
          {isLoading ? (
            <Loading loading background="#0000004d" loaderColor="#0099ff" />
          ) : (
            <div className="row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-8 blog-content mt-5">
                      <h2 className="mb-5 mt-5">{data.title}</h2>
                    <p>
                      {`Published on:  `}
                      <Moment fromNow>{data.createdAt}</Moment>
                    </p>
                    <img
                      src={`/images/${data.thumbnail}`}
                      width="550px"
                      height="auto"
                      alt="thumbnail"
                    />
                    {
                      parse(`${data.content}`)
                    }
                  </div>
                  <div className="col-lg-2"></div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;

