/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { DashboardLayout } from "../../components/admin/Layout";
import ReactDatatable from "@ashvin27/react-datatable";
import jwtDecode from "jwt-decode";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import "moment-timezone";
import toast, { Toaster } from "react-hot-toast";

const Withdraws = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const history = useHistory();
  const [refresh, setrefresh] = useState(false);
  const [data, setdata] = useState([]);

  const columns = [
    {
      key: "email",
      text: "User",
      sortable: true,
    },
    {
      key: "method",
      text: "Payment method",
      sortable: true,
    },
    {
      key: "amount",
      text: "Amount($)",
      sortable: true,
    },
    {
      key: "detail",
      text: "Account detail",
      sortable: true,
    },
    {
      key: "created_at",
      text: "Submit time",
      sortable: true,
      cell: (record, index) => {
        return (
          <>
            <span className="text-nowrap">
              <Moment fromNow>{record.createdAt}</Moment>
            </span>
          </>
        );
      },
    },
    {
      key: "status",
      text: "Staus",
      sortable: true,
      cell: (record, index) => {
        return (
          <>
            {record.status === "approved" ? (
              <span
                onClick={() => editwithdraw(record.id, record.status)}
                className="status"
              >
                Approved
              </span>
            ) : (
              <span
                onClick={() => editwithdraw(record.id, record.status)}
                className="status pending"
              >
                Pending
              </span>
            )}
          </>
        );
      },
    },
  ];

  const editwithdraw = async (id, status) => {
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const data = {
      status: status,
      id: id,
    };
    try {
      const response = await axios.post(`/api/editwithdraw`, data, config);
      setrefresh(true);
      toast.success(response.data.msg);
    } catch (err) {}
  };

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",
  };

  useEffect(async () => {
    const token = Cookies.get("token");
    if (token) {
      const decode = jwtDecode(token);
      if (decode.user.rule !== "admin") {
        history.push("/");
      }
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const data = {
        email: decode.user.email,
      };
      try {
        const response = await axios.post(`/api/transections`, data, config);
        setdata(response.data.transections);
      } catch (err) {}
    } else {
      history.push("/");
    }
    setrefresh(false);
  }, [refresh]);
  return (
    <DashboardLayout
      isSidebarOpen={isSidebarOpen}
      setIsSidebarOpen={setIsSidebarOpen}
    >
      <Toaster position="bottom-right" reverseOrder={false} />
      <div className="main-content">
        <h2>Withdraws</h2>
        <div className="links p-3">
          <div className="table-responsive">
            <ReactDatatable config={config} records={data} columns={columns} />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Withdraws;
