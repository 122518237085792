/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React,{useEffect,useState} from "react";
import { VscMenu } from "react-icons/vsc";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { MdNotificationsNone } from "react-icons/md";
import axios from "axios";

export const Header = ({ setIsSidebarOpen, isSidebarOpen }) => {
  const history = useHistory();
  const [notifications, setnotifications] = useState();
  const logout = () => {
    Cookies.remove("token");
    history.replace("/");
  };
  useEffect( async () => {
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    try {
      const response = await axios.post(`/api/setting`, config);
      setnotifications(response.data.setting.notifications);
    } catch (err) {}
  },[])
  return (
    <nav className="navbar navbar-expand-sm">
      <span
        className="btn-menu"
        onClick={() => {
          if (isSidebarOpen) {
            setIsSidebarOpen(false);
          } else {
            setIsSidebarOpen(true);
          }
        }}
        type="button"
      >
        <VscMenu size="20px" />
      </span>
      <Link className="ml-auto noti-btn" to="/admin/messages">
        <MdNotificationsNone className="notificationBtn" size="30px" />
        <span className="badge badge-success badge-pill">{notifications}</span>
      </Link>
      <div className="dropdown mr-3">
        <button type="button" className="btn" data-toggle="dropdown">
          <img
            src="/adminProfile.png"
            height="auto"
            width="35px"
            alt="profile"
          />
        </button>
        <div className="dropdown-menu">
          <Link className="dropdown-item" to="/setting">
            My Profile
          </Link>
          <Link className="dropdown-item" to="/dashboard">
            Dashboard
          </Link>

          <button className="dropdown-item" onClick={logout}>
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
};
