/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Header from "../components/header";
import CreateLink from "../components/home/create-link";
import { FiCheckCircle } from "react-icons/fi";
import Footer from "../components/footer";
import axios from "axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Index = () => {
  const [isLoading, setisLoading] = useState(false);
  const [data, setdata] = useState([]);
  const { t } = useTranslation();

  useEffect(async () => {
    setisLoading(true);
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const data = {};
    try {
      const response = await axios.post(`/api/posts`, data, config);
      setdata(response.data.recentposts);
    } catch (err) {}
    setisLoading(false);
  }, []);
  return (
    <div className="index">
      <svg
        className="header-bg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#0099ff"
          fillOpacity="1"
          d="M0,32L48,64C96,96,192,160,288,170.7C384,181,480,139,576,144C672,149,768,203,864,224C960,245,1056,235,1152,234.7C1248,235,1344,245,1392,250.7L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <Header />
      <div className="mcontainer mt-3">
        <div className="row">
          <div className="col-lg-7 mt-5">
            <CreateLink />
          </div>
          <div className="col-lg-5 top-content">
            <h4>{t("Latest Posts")}</h4>
            <div class="row mt-3">
              {data.map((row) => (
                <div className="col-lg-4 col-sm-4 mb-3">
                  <Link to={`/p/${row.slug}`}>
                    <img
                      src={`/images/${row.thumbnail}`}
                      height="auto"
                      width="100%"
                      alt="thumbnail"
                    />
                    <h5>{row.title}</h5>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="center-content">
          <h3>{t("How does it work?")}</h3>
          <div className="row mt-5">
            <div className="col-lg-6">
              <img src="/home-content.jpg" alt="home-content-img" />
            </div>
            <div className="col-lg-6 mt-5">
              <h2>{t("Features!")}</h2>
              <p className="mt-3">
                {t("Subtounlock turns your links into calls to action so that your visitors do not leave your page or video without becoming your followers")}
              </p>
              <ul>
                <li>
                  <FiCheckCircle className="feature-icon" size="20px" /> {t("Mobile optimized links")}
                </li>
                <li>
                  <FiCheckCircle className="feature-icon" size="20px" /> {t("High conversion volume")}
                </li>
                <li>
                  <FiCheckCircle className="feature-icon" size="20px" />{t("Attention 24/7/365")}
                </li>
                <li>
                  <FiCheckCircle className="feature-icon" size="20px" /> {t("Lock with Social")}
                </li>
                <li>
                  <FiCheckCircle className="feature-icon" size="20px" /> {t("Free to use")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Index;
