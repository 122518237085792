/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { DashboardLayout } from "../../components/admin/Layout";
import ReactDatatable from "@ashvin27/react-datatable";
import jwtDecode from "jwt-decode";
import axios from "axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import Swal from "sweetalert2/dist/sweetalert2.js";
import toast, { Toaster } from "react-hot-toast";
import Moment from "react-moment";
import "moment-timezone";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { BiEdit } from "react-icons/bi";

const Messages = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const history = useHistory();
  const [refresh, setrefresh] = useState(false);
  const [uemail, setuemail] = useState("");
  const [isLoading, setisLoading] = useState();
  const [data, setdata] = useState([]);
  const [title, settitle] = useState();
  const [slug, setslug] = useState();
  const [body, setbody] = useState();
  const [image, setimage] = useState();
  const [title1, settitle1] = useState();
  const [slug1, setslug1] = useState();
  const [body1, setbody1] = useState();
  const [image1, setimage1] = useState(undefined);
  const [publish1, setpublish1] = useState("true");
  const [sploader, setsploader] = useState(false);
  const [publish, setpublish] = useState("true");
  const [id, setid] = useState();
  const columns = [
    {
      key: "thumbnail",
      text: "Thumbnail",
      sortable: true,
      cell: (record, index) => {
        return (
          <>
            <span className="text-nowrap">
              <img
                src={`/images/${record.thumbnail}`}
                height="50px"
                width="auto"
                alt="thumbnail"
              />
            </span>
          </>
        );
      },
    },
    {
      key: "title",
      text: "Title",
      sortable: true,
    },
    {
      key: "publish",
      text: "Published",
      sortable: true,
    },
    {
      key: "createdAt",
      text: "Time",
      sortable: true,
      cell: (record, index) => {
        return (
          <>
            <span className="text-nowrap">
              <Moment fromNow>{record.createdAt}</Moment>
            </span>
          </>
        );
      },
    },
    {
      key: "id",
      text: "Delete",
      sortable: true,
      cell: (record, index) => {
        return (
          <div className="text-nowrap">
            <span
              className="action-btn red"
              onClick={() => deleteLink(record.id)}
            >
              <AiOutlineDelete size="20px" />
            </span>
            <span
              data-toggle="modal"
              data-target="#editModal"
              className="action-btn ml-3 blue"
              onClick={() => editpost(record.id)}
            >
              <BiEdit size="20px" />
            </span>
          </div>
        );
      },
    },
  ];
  const editpost = (id) => {
    setsploader(true);
    const getpost = data.filter((post) => post.id === id);
    settitle1(getpost[0].title);
    setslug1(getpost[0].slug);
    setbody1(getpost[0].content);
    setpublish1(getpost[0].publish);
    setid(getpost[0].id);
    setsploader(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setisLoading(true);
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const formData = new FormData();
    formData.append("title", title);
    formData.append("body", body);
    formData.append("publish", publish);
    formData.append("image", image[0]);
    formData.append("slug", slug);
    axios
      .post(`/api/createPost`, formData, config)
      .then((res) => {
        toast.success(res.data.msg);
        setisLoading(false);
        settitle("");
        setslug("");
        setbody("");
        setpublish("true");
        setrefresh(true);
      })
      .catch((err) => {
        toast.error(err.response.data.msg);
        setisLoading(false);
      });
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    setisLoading(true);
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const formData = new FormData();
    formData.append("title", title1);
    formData.append("body", body1);
    formData.append("publish", publish1);
    if(image1 !== undefined){
      formData.append("image", image1[0]);
    }else{
      formData.append("image", image1);
    }
    formData.append("slug", slug1);
    formData.append("id", id);
    axios
      .post(`/api/updatePost`, formData, config)
      .then((res) => {
        toast.success(res.data.msg);
        setisLoading(false);
        setrefresh(true);
      })
      .catch((err) => {
        toast.error(err.response.data.msg);
        setisLoading(false);
      });
  };
  const handlechgangetitle = (e) => {
    settitle(e.target.value);
    setslug(e.target.value.trim().split(" ").join("-"));
  };
  const handlechangeslug = (e) => {
    setslug(e.target.value.split(" ").join("-"));
  };
  const handlechgangetitle1 = (e) => {
    settitle1(e.target.value);
    setslug1(e.target.value.trim().split(" ").join("-"));
  };
  const handlechangeslug1 = (e) => {
    setslug1(e.target.value.split(" ").join("-"));
  };
  const deleteLink = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const config = {
          headers: { "Content-Type": "application/json" },
        };
        const data = {
          id: id,
          email: uemail,
        };
        try {
          await axios.post(`/api/deletepost`, data, config);
          Swal.fire("Deleted!", "Post has been deleted.", "success");
          setrefresh(true);
        } catch (err) {}
      }
    });
  };

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",
  };

  useEffect(async () => {
    const token = Cookies.get("token");
    if (token) {
      const decode = jwtDecode(token);
      if (decode.user.rule !== "admin") {
        history.push("/");
      }
      setuemail(decode.user.email);
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const data = {
        email: decode.user.email,
      };
      try {
        const response = await axios.post(`/api/posts`, data, config);
        setdata(response.data.posts);
      } catch (err) {}
    } else {
      history.push("/");
    }
    setrefresh(false);
  }, [refresh]);
  return (
    <DashboardLayout
      isSidebarOpen={isSidebarOpen}
      setIsSidebarOpen={setIsSidebarOpen}
    >
      <Toaster position="bottom-right" reverseOrder={false} />
      <div className="main-content">
        <div className="d-flex">
          <h2>Posts</h2>
          <button
            type="button"
            class="btn btn-primary ml-5"
            data-toggle="modal"
            data-target="#myModal"
          >
            Add Post
          </button>
        </div>
        <div class="modal fade" id="myModal">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">New Post</h4>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>Title:</label>
                    <input
                      onChange={handlechgangetitle}
                      type="title"
                      value={title}
                      required
                      className="form-control mb-4"
                      id="title"
                    />
                  </div>
                  <div className="form-group">
                    <label>Slug:</label>
                    <input
                      type="slug"
                      value={slug}
                      required
                      onChange={handlechangeslug}
                      className="form-control mb-4"
                      id="slug"
                    />
                  </div>
                  <div className="form-group">
                    <label>Description:</label>
                    <SunEditor
                      setContents={body}
                      onChange={(e) => setbody(e)}
                      setOptions={{
                        height: 320,
                        imageFileInput: false,
                        buttonList: [
                          ["undo", "redo"],
                          ["font", "fontSize", "formatBlock"],
                          ["paragraphStyle", "blockquote"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor", "textStyle"],
                          "/", // Line break
                          ["outdent", "indent"],
                          ["align", "horizontalRule", "list", "lineHeight"],
                          [
                            "table",
                            "link",
                            "image",
                            "video",
                            "audio" /** ,'math' */,
                          ], // You must add the 'katex' library at options to use the 'math' plugin. // You must add the "imageGalleryUrl".
                          ["preview"],
                        ],
                      }}
                    />
                    ;
                  </div>
                  <div className="form-group">
                    <label>Thumbnail:</label>
                    <input
                      type="file"
                      required
                      onChange={(e) => setimage(e.target.files)}
                      className="form-control mb-4"
                      id="thumbnail"
                    />
                  </div>
                  <div className="form-group">
                    <label>Publish:</label>
                    <select
                      name="publish"
                      value={publish}
                      onChange={(e) => setpublish(e.target.value)}
                      id="publish"
                      className="form-control mb-4"
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                  {isLoading ? (
                    <button
                      type="submit"
                      disabled
                      className="btn mt-4 form-control disabled btn-primary"
                    >
                      <span className="spinner-border"></span>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn mt-4 form-control  btn-primary"
                    >
                      Publish
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="editModal">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Edit Post</h4>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <form onSubmit={handleUpdate}>
                  <div className="form-group">
                    <label>Title:</label>
                    <input
                      onChange={handlechgangetitle1}
                      type="title"
                      value={title1}
                      required
                      className="form-control mb-4"
                      id="title"
                    />
                  </div>
                  <div className="form-group">
                    <label>Slug:</label>
                    <input
                      type="slug"
                      value={slug1}
                      required
                      onChange={handlechangeslug1}
                      className="form-control mb-4"
                      id="slug"
                    />
                  </div>
                  <div className="form-group">
                    <label>Description:</label>
                    <SunEditor
                      setContents={body1}
                      onChange={(e) => setbody1(e)}
                      setOptions={{
                        height: 320,
                        imageFileInput: false,
                        buttonList: [
                          ["undo", "redo"],
                          ["font", "fontSize", "formatBlock"],
                          ["paragraphStyle", "blockquote"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor", "textStyle"],
                          "/", // Line break
                          ["outdent", "indent"],
                          ["align", "horizontalRule", "list", "lineHeight"],
                          [
                            "table",
                            "link",
                            "image",
                            "video",
                            "audio" /** ,'math' */,
                          ], // You must add the 'katex' library at options to use the 'math' plugin. // You must add the "imageGalleryUrl".
                          ["preview"],
                        ],
                      }}
                    />
                    ;
                  </div>
                  <div className="form-group">
                    <label>Thumbnail:</label>
                    <input
                      type="file"
                      onChange={(e) => setimage1(e.target.files)}
                      className="form-control mb-4"
                      id="thumbnail"
                    />
                  </div>
                  <div className="form-group">
                    <label>Publish:</label>
                    <select
                      name="publish"
                      value={publish1}
                      onChange={(e) => setpublish1(e.target.value)}
                      id="publish"
                      className="form-control mb-4"
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                  {isLoading ? (
                    <button
                      type="submit"
                      disabled
                      className="btn mt-4 form-control disabled btn-primary"
                    >
                      <span className="spinner-border"></span>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn mt-4 form-control  btn-primary"
                    >
                      Update
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="links p-3 mt-3">
          <div className="table-responsive">
            <ReactDatatable config={config} records={data} columns={columns} />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Messages;
