import React from "react";
import { IoIosStats, IoMdCopy } from "react-icons/io";
import copy from "copy-to-clipboard";
import { AiOutlineDelete } from "react-icons/ai";

export const Stat = ({ title, data, Icon }) => {
  return (
    <div className="stat mt-2 p-4">
      <div className="top d-flex">
        <h1 className="ml-4">{data || 0}</h1>
        <Icon className="icon ml-auto mt-2" size="50px" />
      </div>
      <h5>{title}</h5>
    </div>
  );
};

export const Table = ({ data, deleteLink, toast }) => {
  const domainName = window.location.hostname;
  return (
    <>
      <table className="table mt-4 table-borderless">
        <tbody>
          {data.length !== 0 ? (
            ""
          ) : (
            <div className="notFound">
              <img
                src="/icon_box_null.png"
                width="auto"
                height="60px"
                alt="icon_box_null"
              />
              <h5>There is nothing yet</h5>
            </div>
          )}

          {data.map((link) => (
            <tr key={link.id}>
              <td className="text-nowrap" style={{ width: "40%" }}>
                <div className="title">{link.title}</div>
              </td>
              <td>
                <span className="status">{link.status}</span>
              </td>
              <td className="text-nowrap">
                <span className="stats">
                  <IoIosStats size="20px" />
                </span>
                <span className="ml-2 ">{link.views}</span>
              </td>
              <td className="text-nowrap">
                <span
                  className="action-btn mr-3"
                  onClick={() => {
                    copy(`${domainName}/${link.shortId}`);
                    toast.success("Link copied!");
                  }}
                >
                  <IoMdCopy size="20px" />
                </span>
                <span
                  className="action-btn"
                  onClick={() => deleteLink(link.id)}
                >
                  <AiOutlineDelete size="20px" />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
