/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Link } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Email required!");
    } else if (!password) {
      toast.error("Password required!");
    } else {
      setisLoading(true);
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const data = {
        email: email,
        password: password,
      };
      axios
        .post(`/api/login`, data, config)
        .then((res) => {
          toast.success(res.data.msg);
          Cookies.set("token", res.data.token, { expires: 7 });
          setisLoading(false);
          var timeleft = 2;
          var downloadTimer = setInterval(function () {
            if (timeleft <= 0) {
              clearInterval(downloadTimer);
              history.push("/");
            }
            timeleft -= 1;
          }, 1000);
        })
        .catch((err) => {
          toast.error(err.response.data.msg);
          setisLoading(false);
        });
    }
  };
  useEffect(() => {
    const token = Cookies.get('token');
    if(token){
      history.push("/");
    }
  },[])

  return (
    <div className="login">
      <svg
        className="header-bg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#0099ff"
          fillOpacity="1"
          d="M0,32L48,64C96,96,192,160,288,170.7C384,181,480,139,576,144C672,149,768,203,864,224C960,245,1056,235,1152,234.7C1248,235,1344,245,1392,250.7L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <Header />
      <Toaster position="bottom-right" reverseOrder={false} />
      <div className="mcontainer">
      <div className="center-content">
        <form onSubmit={handleSubmit}>
          <h4 className="mb-4">{t("Log in")}</h4>
          <div className="form-group">
            <label>{t("Email")}</label>
            <input
              onChange={(e) => setemail(e.target.value)}
              type="email"
              value={email}
              className="form-control mb-4"
              placeholder={t("Enter email")}
              id="email"
            />
          </div>
          <div className="form-group">
            <label>{t("Password")}</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setpassword(e.target.value)}
              className="form-control mb-4"
              placeholder={t("Enter password")}
              id="pwd"
            />
          </div>
          {isLoading ? (
            <button
              type="submit"
              disabled
              className="btn mt-4 form-control disabled btn-primary"
            >
              <span className="spinner-border"></span>
            </button>
          ) : (
            <button
              type="submit"
              className="btn mt-4 form-control  btn-primary"
            >
              {t("Log in")}
            </button>
          )}
          <div className="form-group form-check">
            <div className="bottom">
              <span className="mr-3">{t("You do not have an account?")}</span>
              <Link className="lbtn" to="/register">
                {t("Sign Up")}
              </Link>
            </div>
          </div>
        </form>
      </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
