import React from "react";

import { NavSidebar } from "./NavSidebar";
import { Header } from "../../components/admin/header";

export const DashboardLayout = ({
  children,
  setIsSidebarOpen,
  isSidebarOpen,
}) => {
  return (
    <div className="admin">
      {isSidebarOpen ? (
        <div onClick={() => setIsSidebarOpen(false)} className="overlay"></div>
      ) : (
        ""
      )}
      <div className={`sidebar ${isSidebarOpen ? "" : "margin-left"}`}>
        <NavSidebar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      </div>
      <div className={`main ${isSidebarOpen ? "padding-left" : ""}`}>
        <div className="content">
          <Header
            setIsSidebarOpen={setIsSidebarOpen}
            isSidebarOpen={isSidebarOpen}
          />
          {children}
        </div>
      </div>
    </div>
  );
};
