/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router-dom";
import axios from "axios";
import LanguageSelect from "./languageSelect";
import { useTranslation } from "react-i18next";


function Header(props) {
  const [isLoged, setisLoged] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const [earning, setearning] = useState();
  const [rule, setrule] = useState("");
  const [username, setusername] = useState("");
  const logo = Cookies.get("logo");
  const [settingLogo, setsettinglogo] = useState();


  const logout = () => {
    Cookies.remove("token");
    history.replace("/");
    setisLoged(false);
  };
  
  useEffect(async () => {
    const token = Cookies.get("token");
    if (token) {
      const decode = jwtDecode(token);
      setusername(decode.user.username);
      setrule(decode.user.rule);
      setisLoged(true);
    }
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    try {
      const response = await axios.post(`/api/setting`, config);
      const res = response.data.setting;
      setearning(res.earning);
    } catch (err) {};

    try {
     
      const response = await axios.post(`/api/setting`, config);
	    setsettinglogo(response.data.setting.siteLogo);
      
    } catch (err) {}
    
  }, [props.refresh]);
  return (
    <div className="header">
      
      <nav className="navbar navbar-expand-md navbar-dark">
        <Link className="navbar-brand nav-logo" to="/">
          <img src={ settingLogo }
            width="200px"
            height="auto"
            alt=""
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#collapsibleNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="collapsibleNavbar">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                {t("Home")}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/payout-rates">
                {t("Publisher Rates")}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/blog">
                {t("Blog")}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact">
                {t("Contact")}
              </Link>
            </li>
            <li className="nav-item">
              <LanguageSelect />
            </li>
            {isLoged ? (
              <div className="dropdown">
                <button
                  type="button"
                  className="btn profile-btn"
                  data-toggle="dropdown"
                >
                  <span className="mr-2 username" style={{ color: "white" }}>
                    {username}
                  </span>
                  <img
                    src="/profile.png"
                    height="auto"
                    width="40px"
                    alt="profile"
                  />
                </button>
                <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/setting">
                    {t("My Profile")}
                  </Link>
                  <Link className="dropdown-item" to="/dashboard">
                    {t("Dashboard")}
                  </Link>
                  {earning === "true" ? (
                    <Link className="dropdown-item" to="/withdrawal">
                      {t("Withdrawal")}
                    </Link>
                  ) : (
                    ""
                  )}
                  {rule === "admin" ? (
                    <Link className="dropdown-item" to="/admin/dashboard">
                      {t("Admin Panel")}
                    </Link>
                  ) : (
                    ""
                  )}
                  <button className="dropdown-item" onClick={logout}>
                    {t("Logout")}
                  </button>
                </div>
              </div>
            ) : (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/login">
                    {t("Login")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/register">
                    {t("Signup")}
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Header;
