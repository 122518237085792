/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { FiSearch } from "react-icons/fi";
import Pagination from "../components/dashboard/pagination";
import { IoMdAdd, IoIosStats, IoMdCopy } from "react-icons/io";
import { AiOutlineDelete } from "react-icons/ai";
import axios from "axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import CreateLink from "../components/home/create-link";
import copy from "copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2/dist/sweetalert2.js";
import jwtDecode from "jwt-decode";
import Loading from "react-fullscreen-loading";
import { Stat } from "../components/admin/homeComponents";
import { BiDollar } from "react-icons/bi";
import { MdAccountBalanceWallet } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();
  const pageNumber = 1;
  const [links, setlinks] = useState([]);
  const [uemail, setuemail] = useState("");
  const [id, setid] = useState();
  const history = useHistory();
  const [isLoading, setisLoading] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const domainName = window.location.hostname;
  const [nolinks, setnolinks] = useState(false);
  const [page, setPage] = useState(pageNumber);
  const [pages, setPages] = useState(1);
  const [monthyears, setmonthyears] = useState([]);
  const [chartcategories, setchartcategories] = useState([]);
  const [chartdata, setchartdata] = useState([]);
  const [user, setuser] = useState("");
  //const [tvisitors, settvisitors] = useState();
  const [tvisitors, setMonthlyViews] = useState();

  const deleteLink = async (id) => {
    Swal.fire({
      title: t("Are you sure?"),
      text: t("You won't be able to revert this!"),
      icon: t("warning"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const config = {
          headers: { "Content-Type": "application/json" },
        };
        const data = {
          id: id,
          email: uemail,
        };
        try {
          await axios.post(`/api/delete_link`, data, config);
          Swal.fire(t("Deleted!"), t("Link has been deleted."), t("success"));
          setrefresh(true);
        } catch (err) {}
      }
    });
  };

  const handlesearch = async (e) => {
    const value = e.target.value;
    if (value === "") {
      setrefresh(true);
    } else {
      setisLoading(true);
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const data = {
        value: value,
        email: uemail,
      };
      try {
        const response = await axios.post(`/api/search_links`, data, config);
        setlinks(response.data.response);
        setisLoading(false);
        if (response.data.response.length === 0) {
          setnolinks(true);
        } else {
          setnolinks(false);
        }
      } catch (err) {}
    }
  };

  const editLink = async (id, status) => {
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const data = {
      status: status,
      id: id,
    };
    try {
      await axios.post(`/api/edit_links`, data, config);
      setrefresh(true);
    } catch (err) {}
  };

  const chartstate = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: chartcategories,
      },
    },
    series: [
      {
        name: t("Visitors"),
        data: chartdata,
      },
    ],
  };

  const handlechart = async (e) => {
    setisLoading(true);
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const data = {
      id: id,
      monthyear: e.target.value,
    };
    try {
      const response = await axios.post(`/api/userstats`, data, config);
      const datedata = await response.data.monthdata.map(
        (a) =>
          new Date(a.date).getMonth() + 1 + "-" + new Date(a.date).getDate()
      );
      setchartcategories(datedata);
      const viewdata = await response.data.monthdata.map((a) => a.views);
      setchartdata(viewdata);
      setisLoading(false);
    } catch (err) {
      setisLoading(false);
    }
  };

  useEffect(async () => {
    const token = Cookies.get("token");
    if (token) {
      const decode = jwtDecode(token);
      setuemail(decode.user.email);
      setid(decode.user.id);
      setisLoading(true);
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const data = {
        email: decode.user.email,
        pageNo: page,
      };
      try {
        const response = await axios.post(`/api/users`, data, config);
        setuser(response.data.user[0]);
      } catch (err) {}
      try {
        const response = await axios.post(`/api/user_links`, data, config);
        setlinks(response.data.response);
        setPages(response.data.pages);
        if (response.data.response.length === 0) {
          setnolinks(true);
        } else {
          setnolinks(false);
        }
      } catch (err) {
        setrefresh(false);
      }


     

      const data1 = {
        id: decode.user.id,
      };

      try {
        // setscreenLoading(true);
        const urlLinks = `/api/total-users-view?month=all`;
        const response = await axios.post(urlLinks, data1, config);
        const monthlyViews = [...response.data.data].shift();
        setMonthlyViews(monthlyViews.totalMonthlyViews);
        // setscreenLoading(false);
      } catch (err) {}



      try {
        const response = await axios.post(`/api/userstats`, data1, config);
        const datesArray = await response.data.monthyears.map(
          (v) =>
            new Date(v.date).getFullYear() +
            "-" +
            (new Date(v.date).getMonth() + 1)
        );
        const totalvisitors = await response.data.monthyears.map(
          (a) => a.views
        );
        //settvisitors(totalvisitors.reduce((a, b) => a + b, 0));
        const uniquemonthyear = await [
          ...new Set(datesArray.map((item) => item)),
        ];
        setmonthyears(uniquemonthyear);
        const datedata = await response.data.monthdata.map(
          (a) =>
            new Date(a.date).getMonth() + 1 + "-" + new Date(a.date).getDate()
        );
        setchartcategories(datedata);
        const viewdata = await response.data.monthdata.map((a) => a.views);
        setchartdata(viewdata);
        setrefresh(false);
        setisLoading(false);
      } catch (err) {
        setisLoading(false);
        setrefresh(false);
      }
    } else {
      history.push("/");
    }
  }, [refresh, page]);
  return (
    <div className="dashboard">
      <svg
        className="header-bg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#0099ff"
          fillOpacity="1"
          d="M0,32L48,64C96,96,192,160,288,170.7C384,181,480,139,576,144C672,149,768,203,864,224C960,245,1056,235,1152,234.7C1248,235,1344,245,1392,250.7L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <Toaster position="bottom-right" reverseOrder={false} />
      <Header />
      <div className="container">
        <div className="mcontainer">
          <div className="center-content">
            <div className="top mb-5">
              <div className="row">
                <div className="col-lg-4"></div>
                <div className="col-lg-4">
                  <select
                    name="monthyear"
                    className="form-control"
                    id="monthyear"
                    onChange={handlechart}
                  >
                    {monthyears.map((v) => (
                      <option key={v} value={v}>
                        {new Date(v).toLocaleString("default", {
                          month: "long",
                        }) +
                          " " +
                          new Date(v).getFullYear()}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-4"></div>
              </div>
            </div>
            <div className="row stats">
              <div className="col-lg-4">
                <Stat
                  Icon={BsFillEyeFill}
                  title={t("Total Visitors")}
                  data={`${tvisitors}`}
                />
              </div>
              <div className="col-lg-4">
                <Stat
                  Icon={BiDollar}
                  title={t("Total Earning")}
                  data={`${user.earning}$`}
                />
              </div>
              <div className="col-lg-4">
                <Stat
                  Icon={MdAccountBalanceWallet}
                  title={t("Available balance")}
                  data={`${user.balance}$`}
                />
              </div>
            </div>
            <div className="header mt-5">
              <div className="search-box">
                <input
                  type="search"
                  placeholder="Search Link"
                  name="search"
                  id="search"
                  onChange={handlesearch}
                />
                <button>
                  <FiSearch size="20px" />
                </button>
              </div>
              <button data-toggle="modal" data-target="#myModal" className="ml-auto cl-btn">
                  {t("Create link")} <IoMdAdd className="icon ml-2" size="20px" />
              </button>
              <div className="modal fade" id="myModal">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">{t("Create link")}</h4>
                      <button type="button" className="close" data-dismiss="modal">
                        &times;
                      </button>
                    </div>

                    <div className="modal-body">
                      <CreateLink />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isLoading ? (
              <Loading loading background="#0000004d" loaderColor="#0099ff" />
            ) : (
              <>
                <div className="dtable">
                  <table className="table mt-5 table-borderless">
                    <tbody>
                      {nolinks ? (
                        <h3 className="text-center p-5">
                          {t("There is nothing yet!")}
                        </h3>
                      ) : (
                        ""
                      )}
                      {links.map((link) => (
                        <tr key={link.id}>
                          <td className="text-nowrap">
                            <div className="title">{link.title}</div>
                          </td>
                          <td>
                            <a
                              className="text-nowrap"
                              href={`${link.shortId}`}
                              target="_blank"
                            >
                              {link.destination}
                            </a>
                          </td>
                          <td>
                            <span
                              onClick={() => editLink(link.id, link.status)}
                              className="status"
                            >
                              {link.status}
                            </span>
                          </td>
                          <td className="text-nowrap">
                            <span className="stats">
                              <IoIosStats size="20px" />
                            </span>
                            <span className="ml-2 ">{link.views}</span>
                          </td>
                          <td className="text-nowrap">
                            <span
                              onClick={() => {
                                copy(`${domainName}/${link.shortId}`);
                                toast.success("Link copied!");
                              }}
                              className="action-btn mr-3"
                            >
                              <IoMdCopy size="20px" />
                            </span>
                            <span
                              onClick={() => deleteLink(link.id)}
                              className="action-btn"
                            >
                              <AiOutlineDelete size="20px" />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination page={page} pages={pages} changePage={setPage} />
              </>
            )}
            <h3 className="mt-4">{t("Unique visitors:")}</h3>

            <Chart
              options={chartstate.options}
              series={chartstate.series}
              type="line"
              width="100%"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
