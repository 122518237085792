/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import axios from "axios";
import Loading from "react-fullscreen-loading";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import parse from "html-react-parser";
import "moment-timezone";
import { Helmet } from "react-helmet";

const Post = () => {
  const [isLoading, setisLoading] = useState(false);
  const [data, setdata] = useState([]);
  const [scriptTags, setscriptTags] = useState()

  useEffect(async () => {
    setisLoading(true);
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const data = {};
    try {
      const response = await axios.post(`/api/posts`, data, config);
      setdata(response.data.posts);
    } catch (err) {}
    try {
      const response = await axios.post(`/api/setting`, data, config);
      const res = response.data.setting;
      setscriptTags(res.scriptTags);
    } catch (err) {}
    setisLoading(false);
  }, []);
  return (
    <div className="blog">
      <Helmet>
        {scriptTags}
      </Helmet>
      <svg
        className="header-bg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#0099ff"
          fillOpacity="1"
          d="M0,32L48,64C96,96,192,160,288,170.7C384,181,480,139,576,144C672,149,768,203,864,224C960,245,1056,235,1152,234.7C1248,235,1344,245,1392,250.7L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <Header />
      <div className="mcontainer">
        <div className="center-content">
          {isLoading ? (
            <Loading loading background="#0000004d" loaderColor="#0099ff" />
          ) : (
            <div className="row">
              {data.map((row) => (
                <>
                  <div key={row.id} className="col-lg-2 col-sm-1"></div>
                  <div className="col-lg-8 col-sm-10 blog-content mt-5">
                    <Link to={`/p/${row.slug}`}>
                      <h3 className="mb-5 mt-5">{row.title}</h3>
                    </Link>
                    <p>
                      {`Published on:  `}
                      <Moment fromNow>{row.createdAt}</Moment>
                    </p>
                    <img
                      src={`/images/${row.thumbnail}`}
                      width="450px"
                      height="auto"
                      alt="thumbnail"
                    />
                    {parse(`${row.content}`)}
                  </div>
                  <div className="col-lg-2 col-sm-1"></div>
                </>
              ))}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Post;
