/* eslint-disable jsx-a11y/anchor-is-valid */
import { RiDashboardLine, RiLinksFill } from "react-icons/ri";
import { Navigation } from "react-minimal-side-navigation";
import { useHistory, useLocation } from "react-router-dom";
// import React from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { FiUsers, FiSettings } from "react-icons/fi";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { BiMoney } from "react-icons/bi";
import { TiMessages } from "react-icons/ti";
import { GiPayMoney } from "react-icons/gi";
import { FaBloggerB } from "react-icons/fa";

import React, { useState, useEffect } from "react";
import axios from "axios";


export const NavSidebar = () => {
  const history = useHistory();
  const location = useLocation();
  const logo = Cookies.get("logo");
  const [settingLogo, setsettinglogo] = useState();

  useEffect(async () => {
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    
    try {
     
      const response = await axios.post(`/api/setting`, config);
	   setsettinglogo(response.data.setting.siteLogo);
      
    } catch (err) {}
  }, []);

  return (
    <React.Fragment>
      <div className="sidenav">
        <Link to="/admin/dashboard">
          {/* <img src={settingLogo ? ({settingLogo}) : '/logo.png'} className="ml-4 mt-3 mb-5 logo" alt="logo" height="auto" width="200px" /> */}
          <img src={settingLogo} className="ml-4 mt-3 mb-5 logo" alt="logo" height="auto" width="200px" />
        </Link>
        <Navigation
          activeItemId={location.pathname}
          onSelect={({ itemId }) => {
            history.push(itemId);
          }}
          items={[
            {
              title: "Home",
              itemId: "/admin/dashboard",
              elemBefore: () => (
                <RiDashboardLine size="20px" name="Dashboard" />
              ),
            },
            {
              title: "Links",
              itemId: "/admin/links",
              elemBefore: () => <RiLinksFill size="20px" name="Links" />,
            },
            {
              title: "Users",
              itemId: "/admin/users",
              elemBefore: () => <FiUsers size="20px" name="Users" />,
            },
            {
              title: "Blog",
              itemId: "/admin/blog",
              elemBefore: () => <FaBloggerB size="20px" name="blog" />,
            },
            {
              title: "Messages",
              itemId: "/admin/messages",
              elemBefore: () => <TiMessages size="20px" name="Messages" />,
            },
            {
              title: "Withdraws",
              itemId: "/admin/withdraws",
              elemBefore: () => <BiMoney size="20px" name="withdraws" />,
            },
            {
              title: "Publisher Rates",
              itemId: "/admin/publisherRates",
              elemBefore: () => <GiPayMoney size="20px" name="PublisherRates" />,
            },
            {
              title: "Setting",
              itemId: "/admin/setting",
              elemBefore: () => <FiSettings size="20px" name="Setting" />,
            },
          ]}
        />
      </div>
    </React.Fragment>
  );
};
