/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { DashboardLayout } from "../../components/admin/Layout";
import jwtDecode from "jwt-decode";
import axios from "axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const Users = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loading, setloading] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const history = useHistory();
  const [uemail, setuemail] = useState("");
  const [sname, setsname] = useState("");
  const [sDes, setsDes] = useState("");
  const [paymentMethod, setpaymentMethod] = useState("");
  const [cpc, setcpc] = useState("");
  const [earning, setearning] = useState("");
  const [minWithdraw, setminWithdraw] = useState("");
  const [client, setclient] = useState("");
  const [slot, setslot] = useState("");
  const [banner_large,setbanner] =useState("");
  const [banner_medium,setbannerMedium] =useState("");
  const [banner_small,setbannerSmall] =useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const data = {
      email: uemail,
      minWithdraw: minWithdraw,
      cpc: cpc,
      withdrawMethod: paymentMethod,
      siteTitle: sname,
      siteDescription: sDes,
      earning: earning,
      client:client,
      slot:slot,
      banner_large:banner_large,
      banner_medium:banner_medium,
      banner_small:banner_small,
    };
    try {
      const response = await axios.post(`/api/updateSetting`, data, config);
      toast.success(response.data.msg);
      setloading(false);
      setrefresh(true);
    } catch (err) {
      toast.error(err.response.data.msg);
      setloading(false);
    }
  };

  const handleUpload = async (e, name) => {
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    var formData = new FormData();
    formData.append('name', name);
    formData.append('img', e.target.files[0]);
    try {
      const response = await axios.post(`/api/upload`, formData, config);
      toast.success(response.data.msg);
      if(response.data.name === "icon"){
        Cookies.set("icon",response.data.url)
      }else if(response.data.name === "logo"){
        Cookies.set("logo",response.data.url)
      }
    } catch (err) {
      toast.error(err.response.data.msg);
    }
    
  };

  useEffect(async () => {
    const token = Cookies.get("token");
    if (token) {
      const decode = jwtDecode(token);
      if (decode.user.rule !== "admin") {
        history.push("/");
      }
      setuemail(decode.user.email);
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const data = {
        email: decode.user.email,
      };
      try {
        const response = await axios.post(`/api/setting`, data, config);
        const res = response.data.setting;
        setsname(res.siteTitle);
        setsDes(res.siteDescription);
        setminWithdraw(res.minWithdraw);
        setpaymentMethod(res.withdrawMethod);
        setcpc(res.cpc);
        setslot(res.slot);
        setclient(res.client);
        setbanner(res.banner_large);
        setbannerMedium(res.banner_medium);
        setbannerSmall(res.banner_small);
        setearning(res.earning);
        Cookies.set("title",res.siteTitle)
        Cookies.set("desc",res.siteDescription)
      } catch (err) {}
    } else {
      history.push("/");
    }
    setrefresh(false);
  }, [refresh]);
  return (
    <DashboardLayout
      isSidebarOpen={isSidebarOpen}
      setIsSidebarOpen={setIsSidebarOpen}
    >
      <Toaster position="bottom-right" reverseOrder={false} />
      <div className="main-content">
        <h2>Setting</h2>
        <div className="setting p-4">
          <form onSubmit={handleSubmit}>
            <h4>General</h4>
            <div className="form-group">
              <label>Site name:</label>
              <input
                type="name"
                className="form-control"
                name="sname"
                value={sname}
                onChange={(e) => setsname(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Site description:</label>
              <input
                type="text"
                className="form-control"
                name="sDes"
                value={sDes}
                onChange={(e) => setsDes(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Site Logo:</label>
              <input
                type="file"
                className="form-control"
                name="slogo"
                onChange={(e) => handleUpload(e, "logo")}
              />
            </div>
            <div className="form-group">
              <label>Site icon:</label>
              <input
                type="file"
                className="form-control"
                name="sicon"
                onChange={(e) => handleUpload(e, "icon")}
              />
            </div>
            <h4 className="mt-5">Withdrawal</h4>
            <div className="form-group">
              <label>Earning:</label>
              <select
                className="form-control"
                value={earning}
                onChange={(e) => setearning(e.target.value)}
              >
                <option value="true">Enabled</option>
                <option value="false">Disabled</option>
              </select>
            </div>
            <div className="form-group">
              <label>Payment method:</label>
              <input
                type="text"
                className="form-control"
                name="paymentMethod"
                value={paymentMethod}
                onChange={(e) => setpaymentMethod(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>CPM($) :</label>
              <input
                type="text"
                className="form-control"
                name="cpc"
                value={cpc}
                onChange={(e) => setcpc(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Minimum withdraw($):</label>
              <input
                type="number"
                className="form-control"
                name="minWithdraw"
                value={minWithdraw}
                onChange={(e) => setminWithdraw(e.target.value)}
              />
            </div>
            <h4 className="mt-5">Adsense</h4>
            <div className="form-group">
              <label>Client:</label>
              <input
                type="text"
                className="form-control"
                name="client"
                value={client}
                onChange={(e) => setclient(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Slot:</label>
              <input
                type="text"
                className="form-control"
                name="slot"
                value={slot}
                onChange={(e) => setslot(e.target.value)}
              />
            </div>
            <h4 className="mt-5">BannerAds</h4>
            <div className="form-group" style={{display: "flex"}}>
              <label style={{paddingRight: "18px"}}>Banner 728x90</label>
              <textarea
              id="banner_large" 
                type="textarea"
                style={{overflowY: "scroll",width:"85%"}}
                rows="6"
                className="form-control"
                name="banner_large"
                value={banner_large}
                onChange={(e) => setbanner(e.target.value)}
                ></textarea>
                
            </div>
              
            <div className="form-group" style={{display: "flex"}}>
              <label style={{paddingRight: "18px"}}>Banner 468x60</label>
              <textarea
              id="banner_medium"
                type="textarea"
                style={{overflowY: "scroll",width:"85%"}}
                rows="6"
                className="form-control"
                name="banner_medium"     
                value={banner_medium}
                onChange={(e) => setbannerMedium(e.target.value)}
                ></textarea>
            </div>

             <div className="form-group" style={{display: "flex"}}>
              <label style={{paddingRight: "18px"}}>Banner 336x280</label>
              <textarea
              id="banner_small" 
                type="textarea"
                style={{overflowY: "scroll",width:"85%"}}
                rows="6"
                className="form-control"
                name="banner_small"
                value={banner_small}
                onChange={(e) => setbannerSmall(e.target.value)}
                ></textarea>
                
            </div>
            <div className="form-group">
              {loading ? (
                <button
                  type="submit"
                  disabled
                  className="form-control btn btn-primary mt-4 create-btn mb-3"
                >
                  <span className="spinner-border"></span>
                </button>
              ) : (
                <button
                  type="submit"
                  className="form-control btn btn-primary mt-4 create-btn mb-3"
                >
                  Save
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Users;
