/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Index from "./screens/index";
import Login from "./screens/login";
import Register from "./screens/register";
import Dashboard from "./screens/dashboard";
import LinkPage from "./screens/linkpage";
import withdraw from "./screens/withdraw";
import Contact from "./screens/contact";
import post from "./screens/singlepost";
import Blog from "./screens/blog";
import Payoutrates from "./screens/payoutRates";
import "bootstrap/dist/css/bootstrap.min.css";
import Profile from "./screens/profile";
import AdminHome from "./screens/admin/home";
import AdminLinks from "./screens/admin/links";
import AdminUsers from "./screens/admin/users";
import AdminSetting from "./screens/admin/setting";
import withdrawsSetting from "./screens/admin/withdraws";
import Adminmessages from "./screens/admin/messages";
import AdminPRates from "./screens/admin/publisherRates";
import Adminblog from "./screens/admin/blog";
import "bootstrap";
import "sweetalert2/src/sweetalert2.scss";
import axios from "axios";
import Cookies from "js-cookie";
import "./index.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import { Helmet } from "react-helmet";

function App() {
  const [title, settitle] = useState("");
  const [desc, setdesc] = useState("");
  const [icon, seticon] = useState("");
  useEffect(async () => {
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    try {
      const response = await axios.post(`/api/setting`, config);
      Cookies.set("icon", response.data.setting.siteIcon);
      Cookies.set("logo", response.data.setting.siteLogo);
      Cookies.set("title", response.data.setting.siteTitle);
      Cookies.set("desc", response.data.setting.siteDescription);
      settitle(response.data.setting.siteTitle);
      seticon(response.data.setting.siteIcon);
      setdesc(response.data.setting.siteDescription);
    } catch (err) {}
  }, []);
  return (
    <Router>
      <Helmet>
        <title>
          {title}
          {` - ${desc}`}
        </title>
        <link rel="icon" href={icon} />
      </Helmet>
      <Switch>
        <AnimatedSwitch
          atEnter={{ opacity: 0.2 }}
          atLeave={{ opacity: 0.2 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          <Route path="/" exact component={Index} />
          <Route path="/login" exact component={Login} />
          <Route path="/register" exact component={Register} />
          <Route
            path="/dashboard/:pageid?"
            exact
            render={(props) => <Dashboard {...props} />}
          />
          <Route path="/setting" exact component={Profile} />
          <Route path="/withdrawal" exact component={withdraw} />
          <Route path="/admin/dashboard" exact component={AdminHome} />
          <Route path="/admin/links" exact component={AdminLinks} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/blog" exact component={Blog} />
          <Route path="/payout-rates" exact component={Payoutrates} />
          <Route path="/admin/users" exact component={AdminUsers} />
          <Route path="/admin/setting" exact component={AdminSetting} />
          <Route path="/admin/messages" exact component={Adminmessages} />
          <Route path="/admin/withdraws" exact component={withdrawsSetting} />
          <Route path="/admin/publisherRates" exact component={AdminPRates} />
          <Route path="/admin/blog" exact component={Adminblog} />
          <Route path="/admin" exact>
            <Redirect to="/admin/dashboard" />{" "}
          </Route>
          <Route path="/p/:id" exact component={post} />
          <Route path="/:id" exact component={LinkPage} />
          <Route exact component={Index} />
        </AnimatedSwitch>
      </Switch>
    </Router>
  );
}

export default App;
