/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import axios from "axios";
import Loading from "react-fullscreen-loading";
import ReactCountryFlag from "react-country-flag";
import { countries } from "country-data";
import { useTranslation } from "react-i18next";

const Payoutrates = () => {
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [worldwide, setworldwide] = useState();
  const { t } = useTranslation();

  useEffect(async () => {
    setisLoading(true);
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const data = {};
    try {
      const response = await axios.post(`/api/publisherrates`, data, config);
      setdata(response.data.publisherrates);
    } catch (err) {}
    try {
      const response = await axios.post(`/api/setting`, config);
      setworldwide(response.data.setting.cpc);
    } catch (err) {}
    setisLoading(false);
  }, []);

  return (
    <div className="payoutrates">
      <svg
        className="header-bg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#0099ff"
          fillOpacity="1"
          d="M0,32L48,64C96,96,192,160,288,170.7C384,181,480,139,576,144C672,149,768,203,864,224C960,245,1056,235,1152,234.7C1248,235,1344,245,1392,250.7L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <Header />
      <div className="mcontainer">
        <div className="center-content">
          <h1>{t("PUBLISHER RATES")}</h1>
          {isLoading ? (
            <Loading loading background="#0000004d" loaderColor="#0099ff" />
          ) : (
            <table class="table mt-5">
              <thead>
                <tr>
                  <th className="country">{t("Package Description / Country")}</th>
                  <th className="value">{t("Desktop")}</th>
                  <th className="value">{t("Mobile / Tablet")}</th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                      <td> {t("Worldwide Deal(All Countries)")}</td>
                      <td>${worldwide}</td>
                      <td>${worldwide}</td>
                  </tr>
                {data.map((row) => (
                  <tr>
                    <td>
                      <div className="text-nowrap">
                        <ReactCountryFlag
                          className="emojiFlag"
                          svg
                          countryCode={row.country}
                          style={{
                            fontSize: "2em",
                            lineHeight: "2em",
                          }}
                        />
                        <span className="ml-3">
                          {countries[row.country].name}
                        </span>
                      </div>
                    </td>
                    <td>${row.desktop}</td>
                    <td>${row.mobile}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Payoutrates;
