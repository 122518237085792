/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [username, setusername] = useState("");
  const [npassword, setnpassword] = useState("");
  const [rnpassword, setrnpassword] = useState("");
  const [userid, setuserid] = useState("")
  const [isLoading, setisLoading] = useState(false);
  const [refresh, setrefresh] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Email required!");
    } else if (!username) {
      toast.error("Username required!");
    }else if (!password) {
      toast.error("Enter password!");
    }else if (npassword) {
      if(npassword !== rnpassword){
        toast.error("New password not matched!");
      }else{
        setisLoading(true);
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const data = {
        email: email,
        id:userid,
        username:username,
        password: password,
        npassword:npassword
      };
      axios
        .post(`/api/editprofile`, data, config)
        .then((res) => {
          toast.success(res.data.msg);
          Cookies.set("token", res.data.token, { expires: 7 });
          setisLoading(false);
          setrefresh(true)
          setpassword("")
          setnpassword("")
          setrnpassword("")
        })
        .catch((err) => {
          toast.error(err.response.data.msg);
          setisLoading(false);
        });
      }
    } else {
      setisLoading(true);
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const data = {
        email: email,
        id:userid,
        username:username,
        password: password,
      };
      axios
        .post(`/api/editprofile`, data, config)
        .then((res) => {
          toast.success(res.data.msg);
          Cookies.set("token", res.data.token, { expires: 7 });
          setisLoading(false);
          setrefresh(true)
          setpassword("")
        })
        .catch((err) => {
          toast.error(err.response.data.msg);
          setisLoading(false);
        });
    }
  };
  useEffect(() => {
    const token = Cookies.get('token');
    if(!token){
      history.push("/");
    }else{
      const decode = jwtDecode(token);
      setusername(decode.user.username)
      setemail(decode.user.email)
      setuserid(decode.user.id)
      setrefresh(false);
    }
  },[refresh])

  return (
    <div className="login">
      <svg
        className="header-bg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#0099ff"
          fillOpacity="1"
          d="M0,32L48,64C96,96,192,160,288,170.7C384,181,480,139,576,144C672,149,768,203,864,224C960,245,1056,235,1152,234.7C1248,235,1344,245,1392,250.7L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <Header refresh={refresh} />
      <Toaster position="bottom-right" reverseOrder={false} />
      <div className="center-content">
        <form onSubmit={handleSubmit}>
          <span className="h4">Profile</span>
          <div className="form-group mt-4">
            <label>{t("Username")}</label>
            <input
              onChange={(e) => setusername(e.target.value)}
              type="username"
              value={username}
              className="form-control mb-4"
              id="username"
            />
          </div>
          <div className="form-group">
            <label>{t("Email")}</label>
            <input
              type="email"
              value={email}
              disabled
              className="form-control mb-4"
              id="email"
            />
          </div>
          <span className="h4">{t("Change password")}</span>
          <div className="form-group mt-4">
            <label>{t("New password")}</label>
            <input
              type="password"
              value={npassword}
              onChange={(e) => setnpassword(e.target.value)}
              className="form-control mb-4"
              id="password"
            />
          </div>
          <div className="form-group">
            <label>{t("Confirm new password")}</label>
            <input
              type="password"
              value={rnpassword}
              onChange={(e) => setrnpassword(e.target.value)}
              className="form-control mb-4"
              id="password"
            />
          </div>
          <div className="form-group mt-5">
            <label>{t("Password")}</label>
            <input
              onChange={(e) => setpassword(e.target.value)}
              type="password"
              value={password}
              className="form-control mb-4"
              id="password"
            />
          </div>
          {isLoading ? (
            <button
              type="submit"
              disabled
              className="btn mt-4 form-control disabled btn-primary"
            >
              <span className="spinner-border"></span>
            </button>
          ) : (
            <button
              type="submit"
              className="btn mt-4 form-control  btn-primary"
            >
              {t("Update")}
            </button>
          )}
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
