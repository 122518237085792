/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { DashboardLayout } from "../../components/admin/Layout";
import ReactDatatable from "@ashvin27/react-datatable";
import jwtDecode from "jwt-decode";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import "moment-timezone";

const Users = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const history = useHistory();
  const [refresh, setrefresh] = useState(false);
  const [data, setdata] = useState([]);

  const columns = [
    {
      key: "username",
      text: "Name",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      sortable: true,
    },
    {
      key: "links",
      text: "Total links",
      sortable: true,
    },
    {
      key: "earning",
      text: "Total earning($)",
      sortable: true,
    },
    {
      key: "balance",
      text: "Available balance($)",
      sortable: true,
    },
    {
      key: "withdraw",
      text: "Total withdraw($)",
      sortable: true,
    },
    {
      key: "views",
      text: "Total views",
      sortable: true,
    },
    {
      key: "created_at",
      text: "Joined at",
      sortable: true,
      cell: (record, index) => {
        return (
          <>
            <span className="text-nowrap">
              <Moment fromNow>{record.createdAt}</Moment>
            </span>
          </>
        );
      },
    },
    {
      key: "status",
      text: "Staus",
      sortable: true,
      cell: (record, index) => {
        return (
          <>
            {record.status === "active" ? (
              <span
                onClick={() => editUser(record.id, record.status)}
                className="status"
              >
                Active
              </span>
            ) : (
              <span
                onClick={() => editUser(record.id, record.status)}
                className="status ban"
              >
                Banned
              </span>
            )}
          </>
        );
      },
    },
  ];

  const editUser = async (id, status) => {
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const data = {
      status: status,
      id: id,
    };
    try {
      await axios.post(`/api/editUser`, data, config);
      setrefresh(true);
    } catch (err) {}
  };

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",
  };

  useEffect(async () => {
    const token = Cookies.get("token");
    if (token) {
      const decode = jwtDecode(token);
      if (decode.user.rule !== "admin") {
        history.push("/");
      }
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const data = {
        email: decode.user.email,
      };
      try {
        const response = await axios.post(`/api/users`, data, config);
        setdata(response.data.users);
      } catch (err) {}
    } else {
      history.push("/");
    }
    setrefresh(false);
  }, [refresh]);
  return (
    <DashboardLayout
      isSidebarOpen={isSidebarOpen}
      setIsSidebarOpen={setIsSidebarOpen}
    >
      <div className="main-content">
        <h2>Users</h2>
        <div className="links p-3">
          <div className="table-responsive">
            <ReactDatatable config={config} records={data} columns={columns} />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Users;
