/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { DashboardLayout } from "../../components/admin/Layout";
import { Stat, Table } from "../../components/admin/homeComponents";
import jwtDecode from "jwt-decode";
import axios from "axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { AiOutlineEye, AiOutlineLink } from "react-icons/ai";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FiUsers } from "react-icons/fi";
import Loading from "react-fullscreen-loading";
import toast, { Toaster } from "react-hot-toast";
import Chart from "react-apexcharts";
import Dashboard from "../../components/admin/charts/dashboard";

const Home = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const history = useHistory();
  const [latestLinks, setlatestLinks] = useState([]);
  const [popularLinks, setpopularLinks] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [screenLoading, setscreenLoading] = useState(false);
  const [uemail, setuemail] = useState("");
  const [tUsers, settUsers] = useState();
  const [tLinks, settLinks] = useState();
  // const [tViews, settViews] = useState();
  const [tViews, setMonthlyViews] = useState();
  const [monthyears, setmonthyears] = useState([]);
  const [chartcategories, setchartcategories] = useState([]);
  const [chartdata, setchartdata] = useState([]);
  const [tvisitors, settvisitors] = useState();
  const [isLoading, setisLoading] = useState(false);

  const deleteLink = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const config = {
          headers: { "Content-Type": "application/json" },
        };
        const data = {
          id: id,
          email: uemail,
        };
        try {
          await axios.post(`/api/delete_link`, data, config);
          Swal.fire("Deleted!", "Link has been deleted.", "success");
          setrefresh(true);
        } catch (err) {}
      }
    });
  };
  const chartstate = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: chartcategories,
      },
    },
    series: [
      {
        name: "Visitors",
        data: chartdata,
      },
    ],
  };
  
  //Start change dropdown request.
  const handlechart = async (e) => {
    setisLoading(true);
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const data = {
      monthyear: e.target.value,
    };
    //Request is call when you change the value of dropdown.
    try {
      const response = await axios.post(`/api/adminstats`, data, config);
      const datedata = await response.data.monthdata.map(
        (a) =>
          new Date(a.date).getMonth() + 1 + "-" + new Date(a.date).getDate()
      );
      setchartcategories(datedata);
      const viewdata = await response.data.monthdata.map((a) => a.views);
      setchartdata(viewdata);
      setisLoading(false);
    } catch (err) {
      setisLoading(false);
    }

    const token = Cookies.get("token");
  

    try {
        if (token) {
          const decode = jwtDecode(token);
          if (decode.user.rule !== "admin") {
            history.push("/");
          }
          const config = {
            headers: { "Content-Type": "application/json" },
          };
          const dataset = {
            email: decode.user.email,
          };
        
        
        setscreenLoading(true);
        const urlLinks = `/api/links?month=${data.monthyear}`;
        const response = await axios.post(urlLinks, dataset, config);
        // setlatestLinks(response.data.latestLinks);
        // setpopularLinks(response.data.popularLinks);
        settLinks(response.data.links.length);
        setscreenLoading(false);
      }
    } catch (err) {setisLoading(false);}

    try {
        if (token) {
          const decode = jwtDecode(token);
          if (decode.user.rule !== "admin") {
            history.push("/");
          }
          const config = {
            headers: { "Content-Type": "application/json" },
          };
          const dataset = {
            email: decode.user.email,
          };
        setscreenLoading(true);
        const urlLinks = `/api/users?month=${data.monthyear}`;
        const response = await axios.post(urlLinks, dataset, config);
        settUsers(response.data.users.length);
        // settViews(
        //   response.data.users.map((a) => a.views).reduce((a, b) => a + b, 0)
        // );
        setscreenLoading(false);
      }
    } catch (err) {setscreenLoading(false);}

    try {
      if (token) {
        const decode = jwtDecode(token);
        if (decode.user.rule !== "admin") {
          history.push("/");
        }
        const config = {
          headers: { "Content-Type": "application/json" },
        };
        const dataset = {
          email: decode.user.email,
        };
      setscreenLoading(true);
      const urlLinks = `/api/monthly-views?month=${data.monthyear}`;
      const response = await axios.post(urlLinks, dataset, config);
      var monthlyViews1 = [...response.data.data].shift();
      setMonthlyViews(monthlyViews1.totalMonthlyViews);
      setscreenLoading(false);
    }
  } catch (err) {setscreenLoading(false);}
      
  };

  //End change dropdown request.

  useEffect(async () => {
    const token = Cookies.get("token");
    if (token) {
      const decode = jwtDecode(token);
      if (decode.user.rule !== "admin") {
        history.push("/");
      }
      setscreenLoading(true);
      setuemail(decode.user.email);
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const data = {
        email: decode.user.email,
      };
    
    try{
      //get filter month from api.
      const response = await axios.post(`/api/filter-month-dashboard`, {}, config);
      const datesArray = await response.data.filtermonths.map(
        (v) =>
          new Date(v.FILTER_MONTH).getFullYear() +
          "-" +
          (new Date(v.FILTER_MONTH).getMonth() + 1)
      );
     const uniquemonthyear = await [
        ...new Set(datesArray.map((item) => item) ),
      ];
      setmonthyears(uniquemonthyear);
    }catch (err) {}

      try {
        const response = await axios.post(`/api/links`, data, config);
        setlatestLinks(response.data.latestLinks);
        setpopularLinks(response.data.popularLinks);
      } catch (err) {}
      
      try {
        const response = await axios.post(`/api/users`, data, config);
        settUsers(response.data.users.length);
        // settViews(
        //   response.data.users.map((a) => a.views).reduce((a, b) => a + b, 0)
        // );
      } catch (err) {}


      try {
        setscreenLoading(true);
        const urlLinks = `/api/monthly-views?month=all`;
        const response = await axios.post(urlLinks, data, config);
        var monthlyViews1 = [...response.data.data].shift();
        setMonthlyViews(monthlyViews1.totalMonthlyViews);
        setscreenLoading(false);
      } catch (err) {}

      
      try {
        const response = await axios.post(`/api/links`, data, config);
        settLinks(response.data.links.length);
      } catch (err) {}
      
      const data1 = {};
      try {
        // Request is call when page is load home.jsx.
        const response = await axios.post(`/api/adminstats`, data1, config);
        const totalvisitors = await response.data.monthyears.map(
          (a) => a.views
        );
        const datedata = await response.data.monthdata.map(
          (a) =>
            new Date(a.date).getMonth() + 1 + "-" + new Date(a.date).getDate()
        );
        settvisitors(totalvisitors.reduce((a, b) => a + b, 0));
        setchartcategories(datedata);
        const viewdata = await response.data.monthdata.map((a) => a.views);
        setchartdata(viewdata);
        setrefresh(false);
        setisLoading(false);
      } catch (err) {
        setisLoading(false);
        setrefresh(false);
      }
    } else {
      history.push("/");
    }
    setrefresh(false);
    setscreenLoading(false);
  }, [refresh]);
  return (
    <DashboardLayout
      isSidebarOpen={isSidebarOpen}
      setIsSidebarOpen={setIsSidebarOpen}
    >
      {screenLoading ? (
        <Loading loading background="#0000004d" loaderColor="#0099ff" />
      ) : (
        ""
      )}
      <Toaster position="bottom-right" reverseOrder={false} />
      <div className="main-content">
        <h2>Overview</h2>
        <div className="top mb-5">
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              <select
                name="monthyear"
                className="form-control"
                id="monthyear"
                onChange={handlechart}
              >
                <option value="all">All</option>
                {monthyears.map((v) => (
                  <option key={v} value={v}>
                    {new Date(v).toLocaleString("default", {
                      month: "long",
                    }) +
                      " " +
                      new Date(v).getFullYear()}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-lg-4"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-sm-4">
            <div className="p-3">
              <Stat Icon={AiOutlineEye} title="Total views" data={tViews} />
            </div>
          </div>
          <div className="col-lg-4 col-sm-4">
            <div className="p-3">
              <Stat Icon={AiOutlineLink} title="Total links" data={tLinks} />
            </div>
          </div>
          <div className="col-lg-4 col-sm-4">
            <div className="p-3">
              <Stat Icon={FiUsers} title="Total users" data={tUsers} />
            </div>
          </div>
        </div>
        <h2 className="mt-4">Activity</h2>
        <div className="row">
          <div className="col-lg-6 p-4">
            <div className="dtable p-4">
              <span>Latest links</span>
              <Table data={latestLinks} deleteLink={deleteLink} toast={toast} />
            </div>
          </div>
          <div className="col-lg-6 p-4">
            <div className="dtable p-4">
              <span>Popular links</span>
              <Table
                data={popularLinks}
                deleteLink={deleteLink}
                toast={toast}
              />
            </div>
          </div>
        </div>
        <h3 className="mt-4">Unique visitors</h3>

        <Chart
          options={chartstate.options}
          series={chartstate.series}
          type="line"
          width="100%"
        />
      </div>
    </DashboardLayout>
  );
};

export default Home;
