/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { TiDelete } from "react-icons/ti";
import { IoMdAdd } from "react-icons/io";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import Cookies from "js-cookie";
import { IoMdCopy, IoMdArrowBack } from "react-icons/io";
import jwtDecode from "jwt-decode";
import copy from 'copy-to-clipboard';
import { useTranslation } from "react-i18next";

function CreateLink() {
  const [inputFields, setinputFields] = useState([{ url: "", method: "" }]);
  const { t } = useTranslation();
  const [destination, setdestination] = useState("");
  const [email, setemail] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [title, settitle] = useState("");
  const [shortLink, setshortLink] = useState("");
  const [sls, setsls] = useState(false);
  const domainName = window.location.hostname;


  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      const decode = jwtDecode(token);
      setemail(decode.user.email);
    } else {
      setemail("guest");
    }

    
    
  }, []);

  

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setinputFields(values);
  };

  const handleAddInput = () => {
    setinputFields([...inputFields, { url: "", method: "" }]);
  };

  const handleRemoveInput = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setinputFields(values);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisLoading(true);
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const data = {
      email: email,
      title: title,
      destination: destination,
      social: inputFields,
    };
    axios
      .post(`/api/generate_link`, data, config)
      .then((res) => {
        toast.success(res.data.msg);
        setdestination("");
        settitle("");
        setinputFields([{ url: "", method: "" }]);
        setshortLink(`${domainName}/${res.data.shortId}`);
        setisLoading(false);
        setsls(true);
      })
      .catch((err) => {
        toast.error(err.response.data.msg);
        setisLoading(false);
      });
  };
  return (
    <div className="create-link">
      <Toaster position="bottom-right" reverseOrder={false} />
      {sls ? (
        <>
        <button onClick={() => {setsls(false)}} className="btn btn-primary mt-4" style={{borderRadius:"50px"}}><IoMdArrowBack size="20px" /> {t("Create another!")}</button>
        <h5 className="mt-5">{t("Step 3")}</h5>
        <p>{t("Copy this link and share")}</p>
        <div className="copylink">
          <input className="form-control mr-2"  value={shortLink} />

          <button onClick={() => {copy(shortLink);
                      toast.success("Link copied!");}} className="copy-btn">
            <IoMdCopy size="20px" />
          </button>
        </div>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <h5>{t("Step 1")}</h5>
          <p>{t("Select platforms")}</p>

          {inputFields.map((input, index) => (
            <div key={index} className="socialinputfiels">
              <input
                className="form-control mr-2"
                type="url"
                required
                name="url"
                placeholder="eg: http://www.youtube.com/"
                value={input.url}
                onChange={(event) => handleChangeInput(index, event)}
              />
              <select
                name="method"
                className="form-control mr-2"
                value={input.method}
                required
                onChange={(event) => handleChangeInput(index, event)}
              >
                <option value="">{t("Select method")}</option>
                <option value="youtube">{("Youtube")}</option>
                <option value="facebook">{t("Facebook")}</option>
                <option value="instagram">{t("Instagram")}</option>
                <option value="twitter">{t("Twitter")}</option>
              </select>
              <button onClick={() => handleRemoveInput(index)}>
                <TiDelete size="40px" />
              </button>
            </div>
          ))}
          <button
            className="btn btn-primary add-btn mt-3"
            onClick={() => handleAddInput()}
          >
            {t("Add Link")}
            <IoMdAdd size="20px" />
          </button>
          <h5 className="mt-3">{t("Step 2")}</h5>
          <p>{t("Select destination")}</p>
          <input
            type="url"
            className="form-control"
            required
            placeholder="http://"
            value={destination}
            name="destination"
            onChange={(e) => setdestination(e.target.value)}
          />
          <p className="mt-4">{t("Add a title")}</p>
          <input
            type="title"
            className="form-control"
            required
            placeholder={t("Enter title")}
            value={title}
            onChange={(e) => settitle(e.target.value)}
            name="title"
          />
          {isLoading ? (
            <button
              type="submit"
              disabled
              className="form-control btn btn-primary mt-4 create-btn mb-3"
            >
              <span className="spinner-border"></span>
            </button>
          ) : (
            <button
              type="submit"
              className="form-control btn btn-primary mt-4 create-btn mb-3"
            >
              {t("Create Link")}
            </button>
          )}
        </form>
      )}
    </div>
  );
}

export default CreateLink;
