/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [title, settitle] = useState("");
  const [message, setmesage] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
      setisLoading(true);
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const data = {
        username:name,
        email: email,
        subject: title,
        message:message
      };
      axios
        .post(`/api/contact`, data, config)
        .then((res) => {
          toast.success(res.data.msg);
          setisLoading(false);
          setname("")
          setemail("")
          settitle("")
          setmesage("")
        })
        .catch((err) => {
          toast.error(err.response.data.msg);
          setisLoading(false);
        });
  };
  return (
    <div className="login">
      <svg
        className="header-bg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#0099ff"
          fillOpacity="1"
          d="M0,32L48,64C96,96,192,160,288,170.7C384,181,480,139,576,144C672,149,768,203,864,224C960,245,1056,235,1152,234.7C1248,235,1344,245,1392,250.7L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <Header />
      <Toaster position="bottom-right" reverseOrder={false} />
      <div className="mcontainer">
      <div className="center-content">
        <form onSubmit={handleSubmit}>
          <h4 className="mb-4">{t("Contact us")}</h4>
          <div className="form-group">
            <label>{t("Name")}</label>
            <input
              onChange={(e) => setname(e.target.value)}
              type="name"
              value={name}
              className="form-control mb-4"
              placeholder={t("Enter name")}
              id="name"
              required
            />
          </div>
          <div className="form-group">
            <label>{t("Email")}</label>
            <input
              onChange={(e) => setemail(e.target.value)}
              type="email"
              value={email}
              className="form-control mb-4"
              placeholder={t("Enter email")}
              id="email"
              required
            />
          </div>
          <div className="form-group">
            <label>{t("Subject")}</label>
            <input
              type="Subject"
              value={title}
              onChange={(e) => settitle(e.target.value)}
              className="form-control mb-4"
              placeholder={t("Enter Subject")}
              id="subject"
              required
            />
          </div>
          <div className="form-group">
            <label>{t("Message")}</label>
            <textarea
              type="message"
              cols="10"
              required
              value={message}
              onChange={(e) => setmesage(e.target.value)}
              className="form-control mb-4"
              placeholder={t("Enter message")}
              id="message"
              rows="6"
            ></textarea>
          </div>
          {isLoading ? (
            <button
              type="submit"
              disabled
              className="btn mt-4 form-control disabled btn-primary"
            >
              <span className="spinner-border"></span>
            </button>
          ) : (
            <button
              type="submit"
              className="btn mt-4 form-control  btn-primary"
            >
              {t("Send message")}
            </button>
          )}
        </form>
      </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
