/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { DashboardLayout } from "../../components/admin/Layout";
import ReactDatatable from "@ashvin27/react-datatable";
import jwtDecode from "jwt-decode";
import axios from "axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { IoMdCopy } from "react-icons/io";
import copy from "copy-to-clipboard";
import { AiOutlineDelete } from "react-icons/ai";
import Swal from "sweetalert2/dist/sweetalert2.js";
import toast, { Toaster } from "react-hot-toast";
import Moment from 'react-moment';
import 'moment-timezone';

const Messages = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const history = useHistory();
  const [refresh, setrefresh] = useState(false);
  const [uemail, setuemail] = useState("");
  const [data, setdata] = useState([]);
  const domainName = window.location.hostname;

  const columns = [
    {
      key: "username",
      text: "Username",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      sortable: true,
    },
    {
      key: "subject",
      text: "Subject",
      sortable: true,
    },
    {
      key: "message",
      text: "Message",
      sortable: true,
    },
    {
      key: "createdAt",
      text: "Time",
      sortable: true,
      cell: (record, index) => {
        return (
          <>
            <span className="text-nowrap"><Moment fromNow>{record.createdAt}</Moment></span>
          </>
        );
      },
    },
    {
      key: "id",
      text: "Delete",
      sortable: true,
      cell: (record, index) => {
        return (
          <div className="text-nowrap">
            <span
              className="action-btn red"
              onClick={() => deleteLink(record.id)}
            >
              <AiOutlineDelete size="20px" />
            </span>
          </div>
        );
      },
    },
  ];
  const deleteLink = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const config = {
          headers: { "Content-Type": "application/json" },
        };
        const data = {
          id: id,
          email: uemail,
        };
        try {
          await axios.post(`/api/deletemessage`, data, config);
          Swal.fire("Deleted!", "Message has been deleted.", "success");
          setrefresh(true);
        } catch (err) {}
      }
    });
  };

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",
  };

  useEffect(async () => {
    const token = Cookies.get("token");
    if (token) {
      const decode = jwtDecode(token);
      if (decode.user.rule !== "admin") {
        history.push("/");
      }
      setuemail(decode.user.email);
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const data = {
        email: decode.user.email,
      };
      try {
        const response = await axios.post(`/api/messages`, data, config);
        setdata(response.data.messages);
      } catch (err) {}
    } else {
      history.push("/");
    }
    setrefresh(false);
  }, [refresh]);
  return (
    <DashboardLayout
      isSidebarOpen={isSidebarOpen}
      setIsSidebarOpen={setIsSidebarOpen}
    >
      <Toaster position="bottom-right" reverseOrder={false} />
      <div className="main-content">
        <h2>Messages</h2>
        <div className="links p-3">
          <div className="table-responsive">
          <ReactDatatable config={config} records={data} columns={columns} />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Messages;
